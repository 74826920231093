import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import {
  compareDate,
  getExhibitionSideNavLinks,
} from '../helpers';
import {
  getDataExhibitionLink,
} from '../proxies';

import {
  ExhibitionLinks,
  NavTarget,
  SideNav,
} from '../components';

const ExhibitionsPage = (props = {}) => {
  const allItems = get(props, 'data.allContentfulExhibition.edges', [])
    .map(getDataExhibitionLink);

  const currentItems = allItems
    .filter(item => compareDate('<>', [item.dateStart, item.dateEnd]));

  const pastItems = allItems
    .filter(item => compareDate('<', item.dateEnd));

  const upcomingItems = allItems
    .filter(item => compareDate('>', item.dateStart));

  const navLinks = getExhibitionSideNavLinks(currentItems, pastItems, upcomingItems);

  return (
    <div className="content content--exhibition">
      <SideNav links={navLinks} />
      {currentItems.length ? (
        <Fragment>
          <NavTarget {...navLinks.filter(item => item.slug === 'current').pop()} />
          <ExhibitionLinks
            title="current"
            exhibitions={currentItems}
          />
        </Fragment>
      ) : ''}
      {upcomingItems.length ? (
        <Fragment>
          <NavTarget {...navLinks.filter(item => item.slug === 'upcoming').pop()} />
          <ExhibitionLinks
            title="upcoming"
            exhibitions={upcomingItems}
          />
        </Fragment>
      ) : ''}
      {pastItems.length ? (
        <Fragment>
          <NavTarget {...navLinks.filter(item => item.slug === 'past').pop()} />
          <ExhibitionLinks
            title="past"
            exhibitions={pastItems}
          />
        </Fragment>
      ) : ''}
    </div>
  );
};

export default ExhibitionsPage;

export const query = graphql`
{
  allContentfulExhibition(sort: {fields: [dateFrom], order: DESC}) {
    edges {
      node {
        contentful_id
        createdAt
        dateFrom
        dateTo
        id
        slug
        title
        updatedAt
      }
    }
  }
}
`;
